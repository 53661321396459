// Map special pages to the concrete urls where they live.
export const singletons = {
  frontPage: "/",
  schoolAndEducation: "/skole-og-opplaering",
  scienceArticleFrontPage: "/forskning-og-utvikling",
  skjemaFrontpage: "/innlogget/skjema",
  myPage: "/innlogget",
  toolFrontPage: "/innlogget/verktoy",
  knowledgePagesFrontpage: "/innlogget/tema",
  installerPage: "/installator",
  eventsPage: "/arrangement",
  articlesPage: "/aktuelt",
  eLearningFrontPage: "/kurs",
  learningProfile: "/kurs/profil",
};

/**
 * Sanity contains streams of documents that live under their
 * own url prefixes. That's why we have this mapping to
 * calculate the correct url to show.
 */
const structureMap = {
  conference: "/arrangement",
  tool: "/verktoy",
  preparedness: "/beredskap",
  single: "/",
  category: "/fagomrade",
  renbladCollection: "/renbladserie",
  schoolAndEducation: "/skole-og-opplaering",
  installerPage: "/installator",
  eventsPage: "/arrangement",
  eLearningFrontPage: "/kurs",
  eLearningCoursePage: "/kurs",
  learningProfile: "/kurs/profil",
  frontPage: "/",
  service: "/tjenester",
  article: "/artikkel",
  about: "/om-oss",
  scienceArticle: "/forskning-og-utvikling",
  userGuide: "/brukerguide",
  myPageSubPage: "/innlogget",
  standalone: "/",
  landingPage: "/l",
  page: "/innlogget/tema", // TODO: Make generic and reusable
  organization: "/innlogget/beredskap",
};

const removeDraft = id => {
  if (id && id.startsWith("drafts.")) {
    return id.replace("drafts.", "");
  }
  return id;
};

/**
  @param doc  Sanity.htmlRenblad | Sanity.htmlSnapshot | Sanity.htmlNote

*/

export const resolveSnapshotLinkFromDoc = (doc, renblad = "") => {
  let slug = "";
  let version = "";
  let blockId = "";

  switch (doc._type) {
    case "htmlRenblad":
      slug = doc.seriesNumber.current || doc.seriesNumber;
      break;
    case "htmlSnapshot":
      slug =
        doc.renblad.snapshotRenblad.seriesNumber.current ||
        doc.renblad.snapshotRenblad.seriesNumber;
      version =
        (doc.renblad.snapshotRenblad.version &&
          doc.renblad.snapshotRenblad.version.current) ||
        doc.renblad._id;

      break;
    // @TODO: Avoid this eslint error if possible
    /* eslint-disable no-case-declarations */
    case "htmlNote":
      const locationToLinkTo =
        doc.location.find(elem => elem.renblad._id == renblad) ||
        doc.location[0];

      const isNoteOnSnapshot = locationToLinkTo.renblad._type == "htmlSnapshot";
      const relevantRenblad = isNoteOnSnapshot
        ? locationToLinkTo.renblad.snapshotRenblad
        : locationToLinkTo.renblad;

      slug =
        relevantRenblad.seriesNumber.current || relevantRenblad.seriesNumber;
      blockId = locationToLinkTo.blockId;
      version = isNoteOnSnapshot ? locationToLinkTo.renblad._id : "";
      break;
    /* eslint-enable no-case-declarations */
  }

  return `/innlogget/renblad-html/${slug}${
    version ? `?version=${version}` : ""
  }${blockId ? `#${blockId}` : ""}`;
};

export const resolveSanityUrl = (doc = {}) => {
  if (!doc) {
    return null;
  }
  const { _id, _type, slug } = doc;
  const { current = "" } = slug || {};
  const draftlessId = removeDraft(_id);

  if (singletons[draftlessId]) {
    return singletons[draftlessId];
  } else if (structureMap[_type] === "/") {
    // Some Sanity types are streams of top-level urls.
    return `/${current}`;
  } else if (structureMap[_type]) {
    return `${structureMap[_type]}/${current}`;
  }
  // No match.
  return null;
};

export const resolveSanityUrlFilepath = (doc = {}) => {
  if (!doc) {
    return null;
  }
  const { _id, _type, slug } = doc;
  const draftlessId = removeDraft(_id);
  if (singletons[draftlessId]) {
    return singletons[draftlessId];
  } else if (structureMap[_type] === "/") {
    // Some Sanity types are streams of top-level urls.
    return `/[slug]`;
  } else if (structureMap[_type]) {
    if (_type === "page") {
      return `${structureMap[_type]}/[...slug]`;
    } else {
      return `${structureMap[_type]}/[slug]`;
    }
  }
  // No match.
  return null;
};
